<app-loader *ngIf="translationsLoaded === false"></app-loader>
<ion-app *ngIf="translationsLoaded === true" style="justify-content: normal;">

  <meta charset="utf-8" />

  <ion-split-pane contentId="main-content">
    <ion-menu
      menuId="main-menu"
      contentId="main-content"
      type="overlay"
      [hidden]="showSidebar === false"
      (ionWillOpen)="menuOpened()"
      (ionWillClose)="menuClosed()"
    >
      <app-sidebar *ngIf="session.getSessionToken() !== null"></app-sidebar>
    </ion-menu>
    <ion-router-outlet id="main-content">
      <aside id="routing-is-loading" *ngIf="showQueryIsLoading">
        <ion-progress-bar type="indeterminate"></ion-progress-bar>
      </aside>

      <ng-container *ngIf="session.getSessionToken() !== null">
        <app-main-header></app-main-header>
      </ng-container>

      <aside id="bottom-tabs" *ngIf="session.getSessionToken() !== null">
        <div class="bottom-tabs-container">
          <app-bottom-tabs [isMenuOpen]="isMenuOpen"></app-bottom-tabs>
        </div>
      </aside>
    </ion-router-outlet>
  </ion-split-pane>

  <lib-billing-popup [platform]="'my-ucrop'"></lib-billing-popup>
  <app-popup-cookies></app-popup-cookies>

</ion-app>
