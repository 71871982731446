import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    data: {
      title: 'login',
    },
    component: LoginComponent,
  },
  {
    path: 'my-ucrop-home',
    data: {
      title: 'myucrop',
    },
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'administrative-management',
    data: {
      title: 'permission_administrative_management',
    },
    loadChildren: () =>
      import(
        './administrative-management/administrative-management.module'
      ).then((m) => m.AdministrativeManagementModule),
  },
  {
    path: 'admin-panel',
    data: {
      title: 'menu_admin_panel',
    },
    loadChildren: () =>
      import('./admin-panel/admin-panel.module').then(
        (m) => m.AdminPanelModule
      ),
  },
  {
    path: 'plans',
    data: {
      title: 'menu_plans',
    },
    loadChildren: () =>
      import('./plans/plans.module').then((m) => m.PlansModule),
  },
  {
    path: 'billing-addresses',
    data: {
      title: 'billing_addresses',
    },
    loadChildren: () =>
      import('./billing/billing-addresses.module').then(
        (m) => m.BillingAddressesModule
      ),
  },
  {
    path: 'modules',
    data: {
      title: 'modules_header',
    },
    loadChildren: () =>
      import('./modules/modules.module').then((m) => m.ModulesModule),
  },
  {
    path: 'subscriptions',
    data: {
      title: 'menu_subscriptions',
    },
    loadChildren: () =>
      import('./subscriptions/subscriptions.module').then(
        (m) => m.SubscriptionsModule
      ),
  },
  {
    path: 'payment-methods',
    data: {
      title: 'profile_payment_methods',
    },
    loadChildren: () =>
      import('./payments/payments.module').then((m) => m.PaymentsModule),
  },
  {
    path: 'profile',
    data: {
      title: 'profile_profile',
    },
    loadChildren: () =>
      import('./profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: '**',
    redirectTo: 'my-ucrop-home',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
