import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { MainHeaderComponent } from './components/main-header/main-header.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { inputTime } from './pipes/input-time.pipe';
import { MatMenuModule } from '@angular/material/menu';
import { DirtyMessageComponent } from './components/dirty-message/dirty-message.component';
import { SanitizeTextPipe } from './pipes/sanitize-text.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { GraphicHeaderComponent } from './components/graphic-header/graphic-header.component';
import { MetricsHeaderComponent } from './components/metrics-header/metrics-header.component';
import { WeekDaysSelectorComponent } from './components/week-days-selector/week-days-selector.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { FilterByPropertyPipe } from './pipes/filter-by-property.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ReplaceNegativePipe } from './pipes/replace-negative.pipe';
import { ParseIntPipe } from './pipes/parse-int.pipe';
import { AlarmGridTagPipe } from './pipes/alarm-grid-tag.pipe';
import { AlarmGridTitlesPipe } from './pipes/alarm-grid-titles.pipe';
import { ToHexPipe } from './pipes/to-hex.pipe';
import { SortPipe } from './pipes/sort.pipe';
import { NavigationComponent } from './components/navigation/navigation.component';
import { PopupCookiesComponent } from './components/popup-cookies/popup-cookies.component';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { TextAvatarComponent } from './components/text-avatar/text-avatar.component';
import { LoaderComponent } from './components/loader/loader.component';
import { GroundTriangleComponent } from './components/ground-triangle/ground-triangle.component';
import { BottomTabsComponent } from './components/bottom-tabs/bottom-tabs.component';

@NgModule({
  declarations: [
    MainHeaderComponent,
    inputTime,
    DirtyMessageComponent,
    SanitizeTextPipe,
    SafeHtmlPipe,
    ParseIntPipe,
    GraphicHeaderComponent,
    MetricsHeaderComponent,
    WeekDaysSelectorComponent,
    FilterByPropertyPipe,
    ReplaceNegativePipe,
    AlarmGridTagPipe,
    AlarmGridTitlesPipe,
    ToHexPipe,
    SortPipe,
    NavigationComponent,
    PopupCookiesComponent,
    TextAvatarComponent,
    LoaderComponent,
    GroundTriangleComponent,
    BottomTabsComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    SidebarComponent,
    CommonModule,
    IonicModule,
    RouterModule,
    MatExpansionModule,
    MatTabsModule,
    MatMenuModule,
    MatInputModule,
    MatTableModule,
    MatTooltipModule,
    NgxPaginationModule,
  ],
  exports: [
    SidebarComponent,
    MainHeaderComponent,
    inputTime,
    DirtyMessageComponent,
    SanitizeTextPipe,
    SafeHtmlPipe,
    GraphicHeaderComponent,
    MetricsHeaderComponent,
    WeekDaysSelectorComponent,
    FilterByPropertyPipe,
    ToHexPipe,
    SortPipe,
    NavigationComponent,
    PopupCookiesComponent,
    TextAvatarComponent,
    LoaderComponent,
    GroundTriangleComponent,
    ParseIntPipe,
    BottomTabsComponent,
  ],
  providers: [MainHeaderComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppCommonsModule {}
