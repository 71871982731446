import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Services
import { HttpRepositoryService } from '../repositories/http-repository.service';
import {
  CheckAuthUserDataModel,
  ConfirmationCodeModel,
  CountriesDataModel,
  LatestLegalAgreementsVersionDataModel,
  LegalAgreementsDataModel,
  LoginDataModel,
  RegisterPushTokenDataModel,
  ResendCodeModel,
  ResendCodeResponseModel,
  ResetPasswordModel,
  TokenDataModel,
  TokenModel,
  UserLegalAgreementsVersionDataModel,
} from '../../auth/auth.model';
import {
  BillingAddressAvailableCodCompaniesDataModel,
  BillingAddressDataModel,
  BillingAddressCreationRequestDataModel,
  ChangePasswordDataModel,
  ChangePasswordModel,
  UserDataModel,
  UserProfileModel,
  BillingAddressUpdateRequestDataModel,
} from '../../billing/billing-addresses.model';
import { SessionLibService } from '@nutricontrol/app360-shared';
import {
  InvitationsDataModel,
  InvitationsPendingDataModel,
  LanguagesDataModel,
  LayoutDataModel,
  SidebarDataModel,
} from '../../commons/app-commons.model';
import {
  SubscriptionInvoiceDataModel,
  SubscriptionsAttachableTerminalDataModel,
  SubscriptionsAvailableEntitiesDataModel,
  SubscriptionDataModel,
} from '@nutricontrol/app360-billing-shared';
import {
  DeletePaymentMethodDataModel,
  PaymentMethodDataModel,
  PaymentSetupIntentDataModel,
  SelectDefaultPaymentMethodDataModel,
} from 'src/app/payments/payments.model';
import {
  AvailableForIrrigationConsultingModel, AvailableTerminalsDataModel,
  ModuleDataDataModel,
  ModuleDataModel, ModuleTemplateDataModel, SensorsConfigurationDataModel,
} from 'src/app/modules/modules.model';
import {
  MagnitudeDataModel,
  TerminalDataModel,
} from 'src/app/terminals/terminals.model';
import { TerminalIdentityDataModel } from '@nutricontrol/app360-shared';

@Injectable({
  providedIn: 'root',
})
export class RestApiService {
  constructor(
    private httpRepositoryService: HttpRepositoryService,
    private session: SessionLibService
  ) {}
  public postRegisterPushToken(
    data: RegisterPushTokenDataModel
  ): Observable<any> {
    return this.httpRepositoryService.postRequest(
      'notifications/devices',
      data
    );
  }
  public postRegister(data: UserDataModel): Observable<UserDataModel> {
    return this.httpRepositoryService.postRequest('users', data);
  }
  public getCountries(): Observable<CountriesDataModel> {
    return this.httpRepositoryService.getRequest('common/countries');
  }
  public getMyUCROPTranslations(): Observable<any> {
    return this.httpRepositoryService.getRequest(
      'common/translations?site=my-ucrop'
    );
  }
  public getUserData(): Observable<UserDataModel> {
    return this.httpRepositoryService.getRequest(
      'users/' + this.session.getSessionVid()
    );
  }
  public confirmCode(
    confirmationCode: ConfirmationCodeModel
  ): Observable<UserDataModel> {
    return this.httpRepositoryService.postRequest(
      'users/' + confirmationCode.identifier + '/confirm',
      confirmationCode.data
    );
  }
  public resendCode(
    resendCode: ResendCodeModel
  ): Observable<ResendCodeResponseModel> {
    return this.httpRepositoryService.postRequest(
      'users/' + resendCode.identifier + '/resend-confirm',
      []
    );
  }
  public resetPasswordEmail(data: {
    email: string;
  }): Observable<ResendCodeModel> {
    return this.httpRepositoryService.postRequest(
      'users/' + data.email + '/reset-password',
      []
    );
  }
  public resetPassword(data: {
    email: string;
  }): Observable<ResetPasswordModel> {
    return this.httpRepositoryService.postRequest(
      'users/' + data.email + '/check-reset-password',
      data
    );
  }
  public login(data: LoginDataModel): Observable<UserDataModel> {
    return this.httpRepositoryService.postRequest(
      'auth/' + data.email + '/login',
      { password: data.password }
    );
  }
  public refreshToken(data: TokenDataModel): Observable<TokenModel> {
    return this.httpRepositoryService.postRequest(
      'auth/' + data.vid + '/refresh-token',
      { token: data.token, access_token: data.access_token }
    );
  }
  public putUserData(data): Observable<UserProfileModel> {
    return this.httpRepositoryService.putRequest(
      'users/' + this.session.getSessionVid(),
      data
    );
  }
  public putNewPassword(
    data: ChangePasswordModel
  ): Observable<ChangePasswordDataModel> {
    return this.httpRepositoryService.putRequest(
      'users/' + this.session.getSessionVid() + '/password',
      data
    );
  }
  public deleteUser(data: UserDataModel): Observable<UserDataModel> {
    return this.httpRepositoryService.postRequest(
      'users/' + this.session.getSessionVid() + '/delete',
      data
    );
  }
  public getMenu(): Observable<SidebarDataModel> {
    return this.httpRepositoryService.getRequest('users/menu?mode=my-ucrop');
  }
  public putMenu(data): Observable<SidebarDataModel> {
    return this.httpRepositoryService.putRequest(
      'users/menu?mode=my-ucrop',
      data
    );
  }
  public getLegalAgreementsText(): Observable<LegalAgreementsDataModel> {
    return this.httpRepositoryService.getRequest(
      'common/legal-agreements/latest-text'
    );
  }
  public getLegalAgreementsVersion(): Observable<LatestLegalAgreementsVersionDataModel> {
    return this.httpRepositoryService.getRequest(
      'common/legal-agreements/latest-version'
    );
  }
  public getUserLegalAgreementsVersion(
    data
  ): Observable<UserLegalAgreementsVersionDataModel> {
    return this.httpRepositoryService.getRequest(
      'users/' + data + '/accepted-legal-agreements-version'
    );
  }
  public putUserLegalAgreementsVersion(
    vid,
    data
  ): Observable<UserLegalAgreementsVersionDataModel> {
    return this.httpRepositoryService.putRequest(
      'users/' + vid + '/accepted-legal-agreements-version',
      data
    );
  }
  public getCheckUserAuth(): Observable<CheckAuthUserDataModel> {
    return this.httpRepositoryService.getRequest('auth/check');
  }
  public getTerminalsApiInfo(data): Observable<any> {
    return this.httpRepositoryService.postRequest(
      'farming/multi-terminal/nuve/equipment?q=valves',
      data
    );
  }
  public getPickUpConfig(vid): Observable<any> {
    return this.httpRepositoryService.getRequest(
      'farming/' + vid + '/equipment/pick_up_config'
    );
  }
  public setUpConfig(vid): Observable<any> {
    return this.httpRepositoryService.postRequest(
      'farming/' + vid + '/equipment/pick_up_config',
      []
    );
  }
  public getConfigStatus(vid): Observable<any> {
    return this.httpRepositoryService.getRequest(
      'farming/' + vid + '/equipment/config_status'
    );
  }
  public getTerminalLayoutConfiguration(vid): Observable<any> {
    return this.httpRepositoryService.getRequest(
      'terminals/' + vid + '/configuration'
    );
  }
  public getInvitations(): Observable<InvitationsDataModel> {
    return this.httpRepositoryService.getRequest('users/invitations');
  }
  public getInvitationsPendent(): Observable<InvitationsPendingDataModel> {
    return this.httpRepositoryService.getRequest('users/invitations/pending');
  }
  public putInvitationResponse(vid, data): Observable<InvitationsDataModel> {
    return this.httpRepositoryService.putRequest(
      'users/invitations/' + vid,
      data
    );
  }
  public putResendEmail(vid, data): Observable<InvitationsDataModel> {
    return this.httpRepositoryService.putRequest(
      'users/invitations/' + vid + '/set-as-pending',
      data
    );
  }
  public getLanguages(): Observable<LanguagesDataModel[]> {
    return this.httpRepositoryService.getRequest('common/languages');
  }
  public postMultiGraph(data): Observable<any> {
    return this.httpRepositoryService.postRequest(
      'farming/concurrent-terminal/nuve/charts',
      data
    );
  }
  public postSaveGraph(data): Observable<any> {
    return this.httpRepositoryService.postRequest(
      'analytics/graphs/graph-bookmarks',
      data
    );
  }
  public putSaveGraph(vid, data): Observable<any> {
    return this.httpRepositoryService.putRequest(
      'analytics/graphs/graph-bookmarks/' + vid,
      data
    );
  }
  public getSaveGraph(): Observable<any> {
    return this.httpRepositoryService.getRequest(
      'analytics/graphs/graph-bookmarks'
    );
  }
  public deleteSavedGraph(vid): Observable<any> {
    return this.httpRepositoryService.deleteRequest(
      'analytics/graphs/graph-bookmarks/' + vid
    );
  }

  public postSavedMetric(data): Observable<any> {
    return this.httpRepositoryService.postRequest(
      'analytics/metrics/metric-bookmarks',
      data
    );
  }
  public putSavedMetric(data, vid): Observable<any> {
    return this.httpRepositoryService.putRequest(
      'analytics/metrics/metric-bookmarks/' + vid,
      data
    );
  }
  public deleteSavedMetric(vid): Observable<any> {
    return this.httpRepositoryService.deleteRequest(
      'analytics/metrics/metric-bookmarks/' + vid
    );
  }
  public getSavedMetrics(): Observable<any> {
    return this.httpRepositoryService.getRequest(
      'analytics/metrics/metric-bookmarks'
    );
  }

  /*MÉTODOS DATALOGGER*/
  public putEquipment(vid, data): Observable<any> {
    //Es un post aunque sea el de editar
    return this.httpRepositoryService.postRequest(
      'farming/' + vid + '/equipment',
      data
    );
  }
  public getConnections(vid): Observable<any> {
    return this.httpRepositoryService.getRequest(
      'farming/' + vid + '/connections'
    );
  }
  public postConnections(vid, data): Observable<any> {
    return this.httpRepositoryService.postRequest(
      'farming/' + vid + '/connections',
      data
    );
  }
  public getConnectionsAvailable(vid): Observable<any> {
    return this.httpRepositoryService.getRequest(
      'farming/' + vid + '/connections/available'
    );
  }
  /*public deleteConnections(data, vid): Observable<any>{
    return this.httpRepositoryService.deleteRequest('farming/'+vid+'/connections', data);
  }*/
  public getDataloggerData(vid): Observable<any> {
    return this.httpRepositoryService.getRequest(
      'farming/' + vid + '/nuve/equipment'
    );
  }
  public getCalculations(vid) {
    return this.httpRepositoryService.getRequest(
      'farming/' + vid + '/calculations'
    );
  }
  public postCalculations(vid, data) {
    return this.httpRepositoryService.postRequest(
      'farming/' + vid + '/calculations',
      data
    );
  }
  public postReprogram(vid, data) {
    return this.httpRepositoryService.postRequest(
      'farming/' + vid + '/nuve/reprogram_sdi12',
      data
    );
  }
  public getCalculationsAvailable(vid) {
    return this.httpRepositoryService.getRequest(
      'farming/' + vid + '/calculations/available'
    );
  }
  public getChartsData(vid, data): Observable<any> {
    return this.httpRepositoryService.getRequest(
      'farming/' +
        vid +
        '/charts/data?nexp=' +
        data.nexp +
        '&nent=' +
        data.nent +
        '&physicalType=' +
        data.physicalType +
        '&begin=' +
        data.begin +
        '&end=' +
        data.end +
        '&interval=' +
        data.interval
    );
  }
  public postChartsData(vid, data): Observable<any> {
    return this.httpRepositoryService.postRequest(
      'farming/' + vid + '/nuve/charts',
      data
    );
  }
  public getET0Prediction(vid, data): Observable<any> {
    return this.httpRepositoryService.getRequest(
      'farming/' +
        vid +
        '/nuve/et0_estimate?begin=' +
        data.begin +
        '&end=' +
        data.end
    );
  }
  public getFAOValue(vid): Observable<any> {
    return this.httpRepositoryService.getRequest(
      'farming/' + vid + '/nuve/fao_crops'
    );
  }
  public getCalculationsdependencies(vid, data): Observable<any> {
    return this.httpRepositoryService.postRequest(
      'farming/' + vid + '/calculations/dependencies',
      data
    );
  }
  public deleteCalculation(vid, data): Observable<any> {
    return this.httpRepositoryService.postRequest(
      'farming/' + vid + '/remove_calculation',
      data
    );
  }
  public deleteConnection(vid, data): Observable<any> {
    return this.httpRepositoryService.postRequest(
      'farming/' + vid + '/disconnect',
      data
    );
  }
  public getConnectionsdependencies(vid, data): Observable<any> {
    return this.httpRepositoryService.postRequest(
      'farming/' + vid + '/connections/dependencies',
      data
    );
  }

  public putConnections(vid, data): Observable<any> {
    return this.httpRepositoryService.putRequest(
      'farming/' + vid + '/connections',
      data
    );
  }
  public putCalculations(vid, data): Observable<any> {
    return this.httpRepositoryService.putRequest(
      'farming/' + vid + '/calculations',
      data
    );
  }

  public getLayout(): Observable<LayoutDataModel> {
    return this.httpRepositoryService.getRequest('users/layout');
  }

  public getBillingAddresses(): Observable<BillingAddressDataModel[]> {
    return this.httpRepositoryService.getRequest('billing-addresses');
  }
  public getBillingAddressAvailableCodCompanies(): Observable<BillingAddressAvailableCodCompaniesDataModel> {
    return this.httpRepositoryService.getRequest(
      'billing-addresses/available-cod-companies'
    );
  }
  public postBillingAddress(
    data: BillingAddressCreationRequestDataModel
  ): Observable<BillingAddressDataModel> {
    return this.httpRepositoryService.postRequest('billing-addresses', data);
  }

  public putBillingAddress(
    billingAddressVid: string,
    data: BillingAddressUpdateRequestDataModel
  ): Observable<BillingAddressDataModel> {
    return this.httpRepositoryService.putRequest(
      'billing-addresses/' + billingAddressVid,
      data
    );
  }

  public deleteBillingAddress(vid): Observable<BillingAddressDataModel> {
    return this.httpRepositoryService.deleteRequest('billing-addresses/' + vid);
  }

  public postResumeSubscription(vid): Observable<any> {
    return this.httpRepositoryService.postRequest(
      'subscriptions/' + vid + '/resume-at-period-end',
      []
    );
  }

  public postCancelSubscription(vid): Observable<any> {
    return this.httpRepositoryService.postRequest(
      'subscriptions/' + vid + '/cancel-at-period-end',
      []
    );
  }

  public attachTerminalsToSubscription(vid, data): Observable<any> {
    return this.httpRepositoryService.postRequest(
      'subscriptions/' + vid + '/attach-terminals',
      data
    );
  }
  public subscriptionAttachableTerminals(): Observable<
    SubscriptionsAttachableTerminalDataModel[]
  > {
    return this.httpRepositoryService.getRequest(
      'subscriptions/attachable-terminals'
    );
  }
  public detachTerminalsFromSubscription(vid, data): Observable<any> {
    return this.httpRepositoryService.postRequest(
      'subscriptions/' + vid + '/detach-terminals',
      data
    );
  }

  public getSubscriptions(): Observable<SubscriptionDataModel[]> {
    return this.httpRepositoryService.getRequest('subscriptions');
  }
  public getSubscriptionByVid(vid: string): Observable<SubscriptionDataModel> {
    return this.httpRepositoryService.getRequest('subscriptions/' + vid);
  }
  public postSubscriptions(data): Observable<SubscriptionDataModel[]> {
    return this.httpRepositoryService.postRequest('subscriptions', data);
  }
  public postSubscriptionsQuotes(data): Observable<any> {
    return this.httpRepositoryService.postRequest('subscriptions/quotes', data);
  }
  public getSubscriptionInvoices(
    subscriptionVid
  ): Observable<SubscriptionInvoiceDataModel[]> {
    return this.httpRepositoryService.getRequest(
      'subscriptions/' + subscriptionVid + '/invoices'
    );
  }

  public getSubscriptionsAvailableEntities(): Observable<SubscriptionsAvailableEntitiesDataModel> {
    return this.httpRepositoryService.getRequest(
      'subscriptions/available-entities'
    );
  }

  public getExcludedEntitiesFromBilling(): Observable<
    TerminalIdentityDataModel[]
  > {
    return this.httpRepositoryService.getRequest(
      'subscriptions/excluded-entities'
    );
  }

  public getPaymentMethods(): Observable<PaymentMethodDataModel[]> {
    return this.httpRepositoryService.getRequest('payment-methods');
  }

  public deletePaymentMethods(vid): Observable<DeletePaymentMethodDataModel> {
    return this.httpRepositoryService.deleteRequest('payment-methods/' + vid);
  }

  public postDefaultPaymentMethods(
    data,
    vid
  ): Observable<SelectDefaultPaymentMethodDataModel> {
    console.log('DEFAULT ', data);

    return this.httpRepositoryService.postRequest(
      'payment-methods/' + vid + '/set-as-default',
      data
    );
  }

  public postSetupIntent(data): Observable<PaymentSetupIntentDataModel> {
    return this.httpRepositoryService.postRequest('stripe/setup-intent', data);
  }

  public postPaymentMethods(data): Observable<PaymentMethodDataModel> {
    return this.httpRepositoryService.postRequest('payment-methods', data);
  }

  public getActivatedModules(): Observable<string[]> {
    return this.httpRepositoryService.getRequest(
      'modules/find-types-by-activated-modules'
    );
  }
  public getModules(): Observable<ModuleDataModel[]> {
    return this.httpRepositoryService.getRequest('modules');
  }
  public getModulesTemplates(): Observable<ModuleTemplateDataModel[]> {
    return this.httpRepositoryService.getRequest('modules/templates');
  }
  public postModule(config): Observable<any> {
    return this.httpRepositoryService.postRequest('modules', config);
  }

  public putModule(vid: string, config): Observable<any> {
    return this.httpRepositoryService.putRequest('modules/' + vid, config);
  }

  public getModuleData(
    vid: string,
    startingAt: string,
    endingAt: string
  ): Observable<ModuleDataDataModel> {
    return this.httpRepositoryService.getRequest(
      `modules/${vid}/data?starting_at=${startingAt}&ending_at=${endingAt}`
    );
  }

  public getTerminals(): Observable<TerminalDataModel[]> {
    return this.httpRepositoryService.getRequest('terminals');
  }

  public getMagnitudes(terminalVid: string): Observable<MagnitudeDataModel[]> {
    return this.httpRepositoryService.getRequest(
      `farming/${terminalVid}/nuve/magnitudes`
    );
  }

  public getAvailableForIrrigationConsulting(
    vids: string[]
  ): Observable<AvailableForIrrigationConsultingModel[]> {
    return this.httpRepositoryService.postRequest(
      'farming/multi-terminal/nuve/availableForIrrigationConsulting',
      { method: 'get', terminals_vid: vids }
    );
  }

  public getAvailableTerminalsFor360(): Observable<AvailableTerminalsDataModel> {
    return this.httpRepositoryService.getRequest(
      'modules/specific/sensors-360/available-terminals'
    );
  }
  public getAll360SensorsPaired(): Observable<MagnitudeDataModel[]> {
    return this.httpRepositoryService.getRequest(
      'modules/find-by-type/sensors_360'
    );
  }
  public post360SensorPaired(
    data
  ): Observable<SensorsConfigurationDataModel> {
    return this.httpRepositoryService.postRequest('modules', data);
  }
  public put360SensorPaired(
    vid,
    data
  ): Observable<SensorsConfigurationDataModel> {
    return this.httpRepositoryService.putRequest(`modules/${vid}`, data);
  }
  public delete360SensorPaired(vid): Observable<SensorsConfigurationDataModel> {
    return this.httpRepositoryService.deleteRequest(`modules/${vid}`);
  }
}
