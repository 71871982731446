export const environment = {
  production: true,
  backend: 'https://api-gw.ucrop.net',
  language: 'en',
  google_maps_api: 'AIzaSyCrzZWsKiBzYcMI3PPuENCXBYa1Hfa1Cgo',
  google_maps_api_android: 'AIzaSyCJQl3fML2VigfChPRrooelDgrBrEK5VPM',
  google_maps_api_ios: 'AIzaSyBqFxfwaxKtwFoCfnnrBD2HaaM-_Xy08HU',
  google_maps_static_api: 'AIzaSyAeVz5BLk8oawep9Hmm6XE28O9enpJduYc',
  frontend: 'https://my.ucrop.net/',
  timeStamp: '10/10/2024 09:49:25',
  stripe_api:
    'pk_live_51MSFPSIckW40TMhSCYAvZ1uCkolCKcitG2fKMcbK6zZeEgEoveE7u7GoMBA244df5Z0wWr2ZuhYYIV8yxcixd3Ep00PEOmcFCe',
};
